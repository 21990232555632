import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";

const Body = styled.p`
  font-size: ${typographySizes.s}rem;
  line-height: ${typographySizes.sh};
  color: var(--rest);
`;

export default Body;
