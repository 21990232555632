import React from "react";
import mediaQuery from "src/assets/styles/mediaQuery";

import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

import typographySizes from "src/assets/styles/typographySizes.js";

import distances from "src/assets/styles/distances.js";
import Arrow from "src/assets/svg/arrow.inline.svg";

const ArrowWrapper = styled.div`
  /* background-color: ${(props) =>
    props.dark ? "var(--bg)" : "var(--rest)"}; */
  /* height: 55rem;
  width: 55rem;
  border-radius: 55rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  #arr {
    fill: ${(props) => (!props.dark ? "var(--bg)" : "var(--rest)")};
    fill: ${(props) => (props.dark ? "var(--bg)" : "var(--rest)")};
  }
`;

const moveArrow = keyframes`
  0% {
    transform:  translate(0rem, 0rem);
  }
  10% {
    transform: translate(55rem, 0rem);
  }
  10.1% {
    transform: translate(-55rem, 0rem);
  }
  20% {
    transform: translate(0rem, 0rem)
  }
  100% {
    transform:  translate(0rem, 0rem)
  }
`;
const StyledArrow = styled(Arrow)`
  width: 28rem;
  &.hovered {
    animation: ${moveArrow} 5s;
    animation-iteration-count: infinite;
    animation-delay: 3s;
  }
`;

const ButtonWrapper = styled(Link)`
  position: relative;

  position: relative;

  display: inline;
  margin-top: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;

  span {
    display: inline-block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    max-width: 0rem;
    white-space: nowrap;
    overflow: hidden;

    font-size: ${typographySizes.s}rem;
    line-height: ${typographySizes.sh};
    color: ${(props) => (props.dark ? "var(--bg)" : "var(--rest)")};
    margin-right: 0;
    &.hovered {
      transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1);
      max-width: 400rem;
      margin-right: ${distances.gap}rem;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1);
      max-width: 400rem;
      margin-right: ${distances.gap}rem;
    }
  }
`;

const Button = ({ children, dark, hover, link, className }) => (
  <ButtonWrapper className={className} to={link} hover={hover} dark={dark}>
    <span className={hover && "hovered"}>{children} </span>
    <ArrowWrapper dark={dark}>
      <StyledArrow className={hover && "hovered"} />
    </ArrowWrapper>
  </ButtonWrapper>
);

export default Button;
