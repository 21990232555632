import React, { useRef } from "react";
import styled from "styled-components";

import Lottie from "lottie-react";
import logoAnimation from "src/assets/we3animation16.json";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const Wrapper = styled.div`
  position: relative;
  top: -5rem;
  div {
    height: 44rem;
    @media (max-width: ${mediaQuery.tablet}) {
      height: 41rem;
    }
  }
  path {
    fill: ${(props) => (props.colorText ? props.colorText : "var(--rest)")};
  }
  svg {
    width: auto !important;
  }
`;

const LogoLotie = ({ colorText }) => {
  const lottieRef = useRef();

  return (
    <Wrapper
      colorText={colorText}
      onMouseEnter={() => {
        lottieRef.current.setDirection(1);
        lottieRef.current.play();
      }}
      onMouseLeave={() => {
        lottieRef.current.setDirection(-1);
        lottieRef.current.play();
      }}
    >
      <Lottie
        lottieRef={lottieRef}
        animationData={logoAnimation}
        loop={false}
        autoplay={false}
      />
    </Wrapper>
  );
};

export default LogoLotie;
