import React from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import typographySizes from "src/assets/styles/typographySizes.js";
import ButtonArrow from "src/components/global/ButtonArrow.js";

import Arrow from "src/assets/svg/arrow.inline.svg";

const StyledButton = styled.button`
  color: ${(props) => (props.color ? props.color : "var(--rest)")};
  position: absolute;
  right: calc(${distances.pageMarginBig}rem + 120rem);
  top: 51rem;
  font-size: ${typographySizes.s}rem;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  .arrow {
    /* transform: scaleX(-1) translate(20rem, -12rem) rotate(-90deg); */
    transform: scaleX(-1) translate(20rem, 0rem);
    margin-right: 0 !important;
  }
  #arr {
    fill: ${(props) => (props.color ? props.color : "var(--rest)")};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    top: 24rem;
    right: 15rem;
    .arrow {
      /* transform: scaleX(-1) translate(20rem, -12rem) rotate(-90deg); */
      transform: scaleX(-1) translate(0rem, 0rem);
      margin-right: 0 !important;
    }
  }
`;

const ArrowWrapper = styled.div`
  /* background-color: ${(props) =>
    props.dark ? "var(--bg)" : "var(--rest)"}; */
  /* height: 55rem;
  width: 55rem;
  border-radius: 55rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform: scaleX(-1) translate(0rem, 0rem);
  #arr {
    fill: ${(props) => (props.color ? props.color : "var(--rest)")};
  }
`;

const moveArrow = keyframes`
  0% {
    transform:  translate(0rem, 0rem);
  }
  10% {
    transform: translate(55rem, 0rem);
  }
  10.1% {
    transform: translate(-55rem, 0rem);
  }
  20% {
    transform: translate(0rem, 0rem)
  }
  100% {
    transform:  translate(0rem, 0rem)
  }
`;
const StyledArrow = styled(Arrow)`
  width: 28rem;
  &.hovered {
    animation: ${moveArrow} 5s;
    animation-iteration-count: infinite;
    animation-delay: 3s;
  }
`;

const ButtonWrapper = styled.button`
  color: ${(props) => (props.color ? props.color : "var(--rest)")};
  position: absolute;
  right: calc(${distances.pageMarginBig}rem + 120rem);
  top: 49rem;
  font-size: ${typographySizes.s}rem;
  z-index: 9999;
  cursor: pointer;

  display: flex;
  text-transform: uppercase;

  display: inline;
  margin-top: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;

  span {
    display: inline-block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    max-width: 0rem;
    white-space: nowrap;
    overflow: hidden;

    font-size: ${typographySizes.s}rem;
    line-height: ${typographySizes.sh};
    color: ${(props) => (props.color ? props.color : "var(--rest)")};
    margin-left: 0;
    &.hovered {
      transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1);
      max-width: 400rem;
      margin-right: ${distances.gap}rem;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1);
      max-width: 400rem;
    }
  }
  :hover {
    span {
      transition: all 0.7s cubic-bezier(0.65, 0, 0.35, 1);
      max-width: 400rem;
      margin-left: ${distances.gap}rem;
    }
    ${StyledArrow} {
      animation: ${moveArrow} 5s;
      animation-iteration-count: infinite;
      animation-delay: 3s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    top: 24rem;
    right: 15rem;
    .arrow {
      /* transform: scaleX(-1) translate(20rem, -12rem) rotate(-90deg); */
      transform: scaleX(-1) translate(0rem, 0rem);
      margin-right: 0 !important;
    }
    span {
      margin-left: ${distances.gap}rem;
    }
  }
`;

const BackButton = ({ previousPageLink, lang, colorText }) => {
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <>
      <Helmet>
        <script>
          {`
            window.onkeydown = function (event) {
              if (event.key === 'Escape') {
                window.history.back();
            }
          `}
        </script>
      </Helmet>
      <ButtonWrapper onClick={handleBack} color={colorText}>
        <ArrowWrapper color={colorText}>
          <StyledArrow />
        </ArrowWrapper>
        <span color={colorText}>{lang === "en" ? ` back` : `wróć`} </span>
      </ButtonWrapper>
    </>
  );
};

// const BackButton = ({ previousPageLink, lang, colorText }) => {
//   const handleBack = () => {
//     navigate(-1); // Go back to the previous page
//   };
//   React.useEffect(() => {
//     localStorage.setItem("previousPage", window.location.href);
//   }, []);
//   return (
//     <>
//       <Helmet>
//         <script>
//           {`
//             window.onkeydown = function (event) {
//               if (event.key === 'Escape') {
//                 window.history.back();
//             }
//           `}
//         </script>
//       </Helmet>
//       <StyledButton color={colorText} onClick={handleBack}>
//         <ButtonArrow className="arrow" /> {lang === "en" ? ` back` : `wróć`}
//       </StyledButton>
//     </>
//   );
// };

export default BackButton;
